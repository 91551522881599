import React from "react";

const FossilBackImg = ( {color} ) => {
    return (
        <svg version="1.1" id="Layer_1" xmlns="http://www.w3.org/2000/svg" x="0px" y="0px" viewBox="0 0 900 900" className="fossil-back-img">
        <polygon points="597.33,160.88 597.33,738.38 303.33,738.38 303.33,193.38 497.33,160.88 " opacity="0.5" fill={color} />
    </svg>
    )
}

export default FossilBackImg;