import React, { useRef, useEffect, useState, useCallback } from "react";
import { ChevronLeft, ChevronRight } from "lucide-react";

const DinosaurTimeline = () => {
  const scrollContainerRef = useRef(null);
  const pageContainerRef = useRef(null);
  const timelineWrapperRef = useRef(null);
  const [currentSlide, setCurrentSlide] = useState(0);
  const [isTimelineLocked, setIsTimelineLocked] = useState(false);
  const [scrollProgress, setScrollProgress] = useState(0);

  const timelineData = [
    {
      era: "1. Permian Period",
      years: "299-251 million years ago",
      description:
        "The period preceding the age of dinosaurs. Dominated by synapsids (ancestors of mammals) and sauropsids (ancestors of reptiles and birds). Ended with the largest known mass extinction event.",
    },
    {
      era: "2. Triassic Period",
      years: "251-199 million years ago",
      description:
        "The dawn of the dinosaurs. Early dinosaurs like Coelophysis and Plateosaurus emerged alongside other reptiles. This period saw the recovery of life after the Permian-Triassic extinction event.",
    },
    {
      era: "3. Jurassic Period",
      years: "199-145 million years ago",
      description:
        "The golden age of dinosaurs. Giants like Brachiosaurus and predators like Allosaurus roamed the Earth. This era saw the evolution of the first birds and a diversification of plant life.",
    },
    {
      era: "4. Cretaceous Period",
      years: "145-66 million years ago",
      description:
        "The last era of dinosaurs. Famous species like T-Rex and Triceratops lived during this time before the mass extinction event. Flowering plants became widespread during this period.",
    },
    {
      era: "5. K-Pg Extinction Event",
      years: "66 million years ago",
      description:
        "A massive asteroid impact led to the extinction of non-avian dinosaurs and many other species. This event marked the end of the Mesozoic Era and the beginning of the Cenozoic Era.",
    },
    {
      era: "6. Paleogene Period",
      years: "66-23 million years ago",
      description:
        "The early part of the Cenozoic Era. Mammals and birds diversified and evolved to fill ecological niches left by extinct dinosaurs. This period includes the Paleocene, Eocene, and Oligocene epochs.",
    },
    {
      era: "7. Neogene Period",
      years: "23-2.6 million years ago",
      description:
        "A time of continued mammalian evolution and the emergence of many modern animal genera. This period saw the evolution of early hominids and the expansion of grasslands.",
    },
    {
      era: "8. Quaternary Period",
      years: "2.6 million years ago - present",
      description:
        "The current geological period, characterized by the evolution of modern humans and recurring ice ages. This period includes the Pleistocene and Holocene epochs.",
    },
  ];

  const checkTimelinePosition = useCallback(() => {
    if (timelineWrapperRef.current) {
      const rect = timelineWrapperRef.current.getBoundingClientRect();
      const windowHeight = window.innerHeight;
      const timelineTop = rect.top;
      const timelineBottom = rect.bottom;
      const timelineHeight = rect.height;
      const viewportCenter = windowHeight / 2;

      if (
        timelineTop <= viewportCenter &&
        timelineBottom >= viewportCenter &&
        !isTimelineLocked
      ) {
        setIsTimelineLocked(true);
      } else if (
        isTimelineLocked &&
        (timelineBottom < viewportCenter || timelineTop > viewportCenter)
      ) {
        if (
          (scrollProgress <= 0 && timelineTop > viewportCenter) ||
          (scrollProgress >= 1 && timelineBottom < viewportCenter)
        ) {
          setIsTimelineLocked(false);
        } else {
          window.scrollTo({
            top:
              window.pageYOffset +
              (timelineTop + timelineHeight / 2 - viewportCenter),
            behavior: "smooth",
          });
        }
      }
    }
  }, [isTimelineLocked, scrollProgress]);

  useEffect(() => {
    let rafId;
    const handleScroll = () => {
      rafId = requestAnimationFrame(checkTimelinePosition);
    };
    window.addEventListener("scroll", handleScroll);
    return () => {
      window.removeEventListener("scroll", handleScroll);
      cancelAnimationFrame(rafId);
    };
  }, [checkTimelinePosition]);

  useEffect(() => {
    const pageContainer = pageContainerRef.current;
    if (!pageContainer) return;

    const handleWheel = (e) => {
      if (isTimelineLocked) {
        e.preventDefault();
        const newProgress = scrollProgress + e.deltaY * 0.0002;
        const clampedProgress = Math.max(0, Math.min(1, newProgress));

        if (clampedProgress !== scrollProgress) {
          setScrollProgress(clampedProgress);
        } else if (
          (newProgress > 1 && currentSlide === timelineData.length - 1) ||
          (newProgress < 0 && currentSlide === 0)
        ) {
          setIsTimelineLocked(false);
        }
      }
    };

    pageContainer.addEventListener("wheel", handleWheel, { passive: false });

    return () => {
      pageContainer.removeEventListener("wheel", handleWheel);
    };
  }, [isTimelineLocked, scrollProgress, currentSlide, timelineData.length]);

  useEffect(() => {
    const container = scrollContainerRef.current;
    if (container) {
      const targetScroll = container.scrollWidth * scrollProgress;
      container.scrollTo({
        left: targetScroll,
        behavior: "smooth",
      });
      const slideIndex = Math.round(scrollProgress * (timelineData.length - 1));
      if (Math.abs(slideIndex - currentSlide) >= 0.1) {
        setCurrentSlide(slideIndex);
      }
    }
  }, [scrollProgress, timelineData.length, currentSlide]);

  const handleArrowClick = (direction) => {
    const step = 1 / (timelineData.length - 1);
    const newProgress =
      direction === "left"
        ? Math.max(0, scrollProgress - step)
        : Math.min(1, scrollProgress + step);
    setScrollProgress(newProgress);
  };

  return (
    <div ref={pageContainerRef} className='page-container'>
      <div className='content-above'>
        <h1>Journey Through Prehistoric Time</h1>
        <p>
          Explore the fascinating eras of dinosaurs in our interactive timeline.
        </p>
      </div>
      <div ref={timelineWrapperRef} className='timeline-wrapper'>
        <div ref={scrollContainerRef} className='timeline-container'>
          {timelineData.map((item, index) => (
            <div key={index} className='timeline-section'>
              <div className='timeline-content'>
                <h2>{item.era}</h2>
                <h3>{item.years}</h3>
                <p>{item.description}</p>
              </div>
            </div>
          ))}
        </div>
        {timelineData.map((item, index) => (
          <div key={index} className="timeline">|</div>
        ))}
        <button
          className='nav-button left-button'
          onClick={() => handleArrowClick("left")}
          disabled={currentSlide === 0}
        >
          <ChevronLeft size={24} />
        </button>
        <button
          className='nav-button right-button'
          onClick={() => handleArrowClick("right")}
          disabled={currentSlide === timelineData.length - 1}
        >
          <ChevronRight size={24} />
        </button>
      </div>
      <div className='additional-content'>
        <h2>Visit Our Museum</h2>
        <p>
          Discover fossils, interactive exhibits, and more about Earth's
          prehistoric past.
        </p>
      </div>
    </div>
  );
};

const styles = `
  .page-container {
    width: 100%;
    height: auto;
    overflow-y: auto;
    font-family: Arial, sans-serif;
    background-color: #ffffff;
    scroll-behavior: smooth;
  }

  .content-above {
    height: 30vh;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    background-color: #f0f0f0;
    padding: 2rem;
    text-align: center;
  }

  .timeline-wrapper {
    position: relative;
    width: 100%;
    height: 50vh;
    background-color: #ffffff;
    overflow: hidden;
  }

  .timeline-container {
    display: flex;
    width: 100%;
    height: 100%;
    overflow-x: hidden;
    scroll-snap-type: x mandatory;
  }

  .timeline-section {
    flex: 0 0 100%;
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    scroll-snap-align: center;
  }

  .timeline-content {
    max-width: 800px;
    width: 90%;
    background-color: #f5f5f5;
    padding: 2rem;
    border-radius: 10px;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  }

  .timeline-section h2 {
    font-size: 2.5rem;
    margin-bottom: 0.5rem;
    color: #333;
  }

  .timeline-section h3 {
    font-size: 1.2rem;
    margin-bottom: 1rem;
    font-weight: normal;
    color: #666;
  }

  .timeline-section p {
    font-size: 1rem;
    line-height: 1.6;
    color: #444;
  }

  .nav-button {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    background-color: rgba(0, 0, 0, 0.1);
    border: none;
    border-radius: 50%;
    width: 40px;
    height: 40px;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    transition: background-color 0.3s;
  }

  .nav-button:hover {
    background-color: rgba(0, 0, 0, 0.2);
  }

  .nav-button:disabled {
    opacity: 0.5;
    cursor: not-allowed;
  }

  .left-button {
    left: 20px;
  }

  .right-button {
    right: 20px;
  }

  .additional-content {
    height: 30vh;
    padding: 2rem;
    background-color: #e0e0e0;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    text-align: center;
  }

  .timeline {
    width: 10px;
    height: 20px;
    background-color: #000;
  }
`;

const DinosaurTimelineWithStyles = () => (
  <>
    <style>{styles}</style>
    <DinosaurTimeline />
  </>
);

export default DinosaurTimelineWithStyles;
