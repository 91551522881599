import React, { Fragment } from "react";
import { Item } from '../../items/item';
import cmsUtils from "../../utils/cmsUtils";
import utils from "../../../utils";
import { Split } from "lucide-react";
import { forEach } from "lodash";

// import DinosaurTimelineItem from "../timelineItem/dinosaurTimeline";

export function PanelTimeline(props) {
    const item = props.item;
    const subItems = item.items || [];
    const cmsOption = props.cmsOption || {};

    const bgUrl = utils.site.resourcePath(cmsUtils.payload(item, "ImageUrl"));
    const bgColor = cmsUtils.payload(item, "BgColor") || "#515151";


    const dinoSubitems = subItems.map((subItem, index) =>
        <Item item={subItem} key={subItem.itemId} cmsOption={cmsOption}></Item>
    );

    // function handleEraClick({yearOfEra}) {

    // }

    // console.log("PanelTimeline", item);
    console.log("Timeline Item", subItems);

    // console.log("image url:", bgUrl, typeof bgUrl);

    const imgImgs = bgUrl.split(',');
    
    const styles = `
.timeline-context-container {
  padding: 5%;
  background-color: rgba(255, 255, 255, 0.5);
  margin: auto auto;
  align-items: center;
  text-align: center;
  justify-items: center;
}

/* timeline start ↓ */
.table-container {
  height: 120px;
  display: flex;
  align-items: flex-start;
  /* width: 3240px; */
  /* gap: 10px; */

  font-size: 12px;
  overflow-x: hidden;

}

button {
  all: unset;
  display: inline-block;
  width: 100%;
  height: 100%;
  cursor: pointer;
}

button:hover {
  background-color:moccasin;
  color: #000;
}

.second-table {
  max-width: calc(100vw - 45px);
  flex-grow: 1;
  /* 右侧表格占据剩余空间 */
  overflow-x: auto;
  /* 启用横向滚动 */
  white-space: nowrap;
  /* 防止右侧表格换行 */
}

.table-hadean {
  writing-mode: vertical-rl;
  /* 竖直文本 */
  height: 91px;
  border: 1px solid #000000;
  justify-content: center;
  /* 水平居中 */
  align-items: center;
  /* 垂直居中 */
  text-align: center;
  /* 文字居中对齐 */
  color: white;
}

/* 第一部分：三行一列，固定宽度 */

.period {
  font-weight: 700;
  display: grid;

  grid-template-rows: repeat(3, px);
  /* 3行 */
  grid-template-columns: 1fr;
  /* 1列 */
  background-color: white;
  /* border: 2px solid black; */
  width: 60px;
  /* 固定宽度 */
}

.period-cell {
  display: flex;
  border: 1px solid black;
  justify-content: center;
  /* 水平居中 */
  align-items: center;
  /* 垂直居中 */
  text-align: center;
  /* 文字居中对齐 */
  height: 30px;
  width: 60px;
  margin: -0.5px;
}

/* 单元格的样式 */
.cell {
  font-weight: 500;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  height: 30px;
  padding: 2px auto;
  text-align: center;
  border: 1px solid #000;
  flex-grow: 1;
  margin: -0.5px;
}

/* 第二部分：复杂表格，占据剩余宽度 */
.second-table {
  display: flex;
  /* justify-content: space-between; */
  width: 100%;
}

.table-archean {
  display: flex;
  flex-direction: column;
  width: 400px;
}

.table-proterozoic {
  display: flex;
  flex-direction: column;
  width: 1200px;
}

.table-phanerozoic {
  display: flex;
  flex-direction: column;
  width: 1800px;
}

.row-1,
.row-2,
.row-3 {
  display: flex;
  flex-wrap: nowrap;
  /* 确保单元格在一行上 */
  width: 100%;

}

.cell-a,
.cell-b,
.cell-c,
.n {
  width: 100px;
}

.d,
.e,
.f {
  width: 150px;
}

.a-group {
  width: 400px;
}

.b-group,
.c-group {
  width: 300px;
}

.d-group {
  width: 900px;
}

.e-group,
.f-group {
  width: 450px;
}
/* timeline end ↑ */

    `;

    return (
        <Fragment>
            <style>{styles}</style>
        
            <div
                className="timeline-main-content"
                style={{
                    backgroundImage: `url(${imgImgs[0]})`,
                    backgroundSize: 'cover',
                    backgroundPosition: 'center',
                    backgroundRepeat: 'no-repeat',
                    width: '100%',
                    height: '100%',
                    padding: "10%",
                }}>

                <div className="timeline-context-container">
                    <h2 style={{
                        width: "80%",
                        margin: "0 auto",
                    }}>
                        Australian Dinosaurs Historic Timeline</h2>
                    <p style={{
                        width: "80%",
                        margin: "0 auto",
                    }}>
                        Over 3,300 footprints of these long-extinct dinosaurs are scattered over the rock face, stark evidence of the terror they must have experienced as they fled the scene upon the arrival of a large theropod. This snapshot of a few terrifying moments has been frozen in time, immortalising the event and making Winton home to the only known dinosaur stampede in the world.
                    </p>
                    <img style={{
                        width: "85%",
                        marginTop: "50px",
                    }}
                        src="/sites/ageofdinosaurs/media/timeline/animals-home-page-(1).png"></img>
                </div>

                <div className="table-container">
                {/* 第一部分：三行一列的表格 */}
                <div className="period">
                    <div className="period-cell">Period</div>
                    <div className="period-cell">Era</div>
                    <div className="period-cell">Eon</div>
                </div>

                {/* 第二部分：复杂表格 */}
                <div className="second-table">
                    <div className="table-hadean">
                        <div style={{ width: "30px", backgroundColor: "#B6158E", fontSize: "500", marginRight: "15px" }}>
                            <button>Hadean</button>
                        </div>
                    </div>
                    {/* 第一行：4列 */}
                    <div className="table-archean">
                        <div className="row row-1">
                            <div className="cell n" style={{ backgroundColor: "#DA008D" }}></div>
                            <div className="cell n" style={{ backgroundColor: "#F366A7" }}></div>
                            <div className="cell n" style={{ backgroundColor: "#F585AF" }}></div>
                            <div className="cell n" style={{ backgroundColor: "#F9ACC4" }}></div>
                        </div>
                        <div className="row row-2">
                            <div className="cell n-group" style={{ backgroundColor: "#DA008D" }}><button>Eoarchean</button></div>
                            <div className="cell n-group" style={{ backgroundColor: "#F366A7" }}><button>Paleoarchean</button></div>
                            <div className="cell n-group" style={{ backgroundColor: "#F585AF" }}><button>Mesoarchean</button></div>
                            <div className="cell n-group" style={{ backgroundColor: "#F9ACC4" }}><button>Neoarchean</button></div>
                        </div>
                        <div className="row row-3">
                            <div className="cell cell-1" style={{ backgroundColor: "#EF008D" }}><button>Archean</button></div>
                        </div>
                    </div>

                    <div className="table-proterozoic">
                        <div className="row row-1">
                            <div className="cell cell-a" style={{ backgroundColor: "#F5718D" }}><button>Siderian</button></div>
                            <div className="cell cell-a" style={{ backgroundColor: "#F57B97" }}><button>Rhyacian</button></div>
                            <div className="cell cell-a" style={{ backgroundColor: "#F685A2" }}><button>Orosirian</button></div>
                            <div className="cell cell-a" style={{ backgroundColor: "#F68FAD" }}><button>Statherian</button></div>
                            <div className="cell cell-b" style={{ backgroundColor: "#FFC793" }}><button>Calymmian</button></div>
                            <div className="cell cell-b" style={{ backgroundColor: "#FFD19F" }}><button>Ectasian</button></div>
                            <div className="cell cell-b" style={{ backgroundColor: "#FFDCAC" }}><button>Stenian</button></div>
                            <div className="cell cell-c" style={{ backgroundColor: "#FFC571" }}><button>Tonian</button></div>
                            <div className="cell cell-c" style={{ backgroundColor: "#FFCF7D" }}><button>Cryogenian</button>
                            </div>
                            <div className="cell cell-c" style={{ backgroundColor: "#FFD989" }}><button>Ediacaran</button>
                            </div>
                        </div>
                        <div className="row row-2">
                            <div className="cell a-group" style={{ backgroundColor: "#F46682" }}><button>Paleoproterozoic</button></div>
                            <div className="cell b-group" style={{ backgroundColor: "#FEBC80" }}><button>Mesoproterozoic</button></div>
                            <div className="cell c-group" style={{ backgroundColor: "#FEBB66" }}><button>Neoproterozoic</button></div>
                        </div>
                        <div className="row row-3">
                            <div className="cell cell-2" style={{ backgroundColor: "#F35A7A" }}><button>Proterozoic</button></div>
                        </div>
                    </div>

                    <div className="table-phanerozoi">
                        <div className="row row-1">
                            <div className="cell d" style={{ backgroundColor: "#8AAB78", color: "white" }}><button>Cambrian</button></div>
                            <div className="cell d" style={{ backgroundColor: "#00A98E", color: "white" }}><button>Ordovician</button></div>
                            <div className="cell d" style={{ backgroundColor: "#B1DDC9" }}><button>Silurian</button></div>
                            <div className="cell d" style={{ backgroundColor: "#D19D5C" }}><button>Devonian</button> </div>
                            <div className="cell d" style={{ backgroundColor: "#64AEB2" }}><button>Carboniferous</button></div>
                            <div className="cell d" style={{ backgroundColor: "#E9654D" }}><button>Permian</button></div>
                            <div className="cell e" style={{ backgroundColor: "#8F51A1", color: "white" }}><button>Triassic</button></div>
                            <div className="cell e" style={{ backgroundColor: "#00B8E5", color: "white" }}><button>Jurassic</button></div>
                            <div className="cell e" style={{ backgroundColor: "#85C870" }}><button>Cretaceous</button></div>
                            <div className="cell f" style={{ backgroundColor: "#FCA972" }}><button>Paleogene</button></div>
                            <div className="cell f" style={{ backgroundColor: "#FFDF37" }}><button>Neogene</button></div>
                            <div className="cell f" style={{ backgroundColor: "#FFF89A" }}><button>Quaternary</button></div>
                        </div>
                        <div className="row row-2">
                            <div className="cell d-group" style={{ backgroundColor: "#9DC1A6" }}><button>Paleozoic</button></div>
                            <div className="cell e-group" style={{ backgroundColor: "#3FC6E8" }}><button>Mesozoic</button></div>
                            <div className="cell f-group" style={{ backgroundColor: "#FBEF39" }}><button>Cenozoic</button></div>
                        </div>
                        <div className="row row-3">
                            <div className="cell cell-3" style={{ backgroundColor: "#8DD7EB" }}><button>Phanerozoic</button></div>
                        </div>
                    </div>

                </div>
            </div>



            </div>

        </Fragment>
    )
}