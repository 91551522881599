import React, { Fragment, useState } from "react";
import cmsUtils from "../../utils/cmsUtils";
import utils from "../../../utils";
import FossilBackImg from "./fossilBack";
import SpecieslBackImg from "./speciesBack";


export function TimelineItem(props) {
    const item = props.item;

    const stateItems = ['WA', 'SA', 'NT', 'QLD', 'NSW', 'ACT', 'VIC', 'TAS'];
    // const [stateItemData, setStateItemData] = useState('');

    const yearOfAge = cmsUtils.payload(item, "YearOfAge");
    const stateAus = cmsUtils.payload(item, "StateAus");
    const yearOfEon = cmsUtils.payload(item, "YearOfEon");
    const yearOfEra = cmsUtils.payload(item, "YearOfEra");
    const yearOfPeriod = cmsUtils.payload(item, "YearOfPeriod");
    const descriptionOfEra = cmsUtils.payload(item, "DescriptionOfEra");
    const briefOfEra = cmsUtils.payload(item, "BriefOfEra");
    const titleOfEra = cmsUtils.payload(item, "TitleOfEra");
    const briefDesOfFossil = cmsUtils.payload(item, "BriefDesOfFossil");
    const mainDesOfFossil = cmsUtils.payload(item, "MainDesOfFossil");
    const bgUrl = utils.site.resourcePath(cmsUtils.payload(item, "ImageUrl"));
    const bgUrl2 = utils.site.resourcePath(cmsUtils.payload(item, "ImageUrl2"));




    const handleStateClick = (e) => {

    }

    const timelineItemStyle = `
.states {
  justify-content: center;
  display: flex;
  align-items: center;
}

.states-ul {
  list-style-type: none;
  display: flex;
  justify-content: center;
}

.states-ul li {
  color: white;
  margin: 0 10px;
  padding: 1px 20px;
  align-items: center;
  justify-items: center;
  text-align: center;
}

.fossil-content {
    width: 500px;
    height: 500px;
}

.species-main-content-container {
    display: flex;
    background-color: rgba(255, 255, 255, 0.5);
}

    `;

    return (
        <React.Fragment>
            <style>{timelineItemStyle}</style>
            <div className="timeline-subitem-main-container">

                <div className="subitem-main-content">
                    <div style={{ width: "80%", height: "80%", paddingBottom: "45%", position: "relative", margin: "0 auto" }}>
                        <div style={{ position: "absolute", top: 0, left: 0, width: "100%", height: "100%" }}>
                            <div className="states">
                                <ul className="states-ul">
                                    {stateItems.map((stateItem, index) => (
                                        <li key={index} style={{ backgroundColor: stateItem === stateAus ? "#653330" : "#515151" }}>
                                            {stateItem}
                                        </li>
                                    ))}
                                </ul>
                            </div>
                            <div className="species-main-content-container">
                                <div className="fossil-content" style={{backgroundImage: <FossilBackImg color={"#FFDF37"} />}}>
                                    {/* <FossilBackImg color={"#FFDF37"} /> */}

                                </div>
                                <div className="species-content">
                                    <SpecieslBackImg color={"#FFFFFF"} />

                                </div>
                            </div>

                        </div>
                        <div className="timeline-bar">
                            <h3>timeline bar</h3>
                        </div>
                    </div>
                </div>



            </div>




            <div>{yearOfAge}</div>
            <div>{stateAus}</div>
            <div>{yearOfEon}</div>
            <div>{yearOfEra}</div>
            <div>{yearOfPeriod}</div>
            <div>{titleOfEra}</div>
            <div>{descriptionOfEra}</div>
            <div>{briefOfEra}</div>
            <div>{briefDesOfFossil}</div>
            <div>{mainDesOfFossil}</div>
            <div><img src={bgUrl} /></div>
            <div><img src={bgUrl2} /></div>


        </React.Fragment>

    )
}
