import React from "react";

const SpecieslBackImg = ({ color }) => {
    return (
        <svg version="1.1" id="Layer_1" xmlns="http://www.w3.org/2000/svg" x="0px" y="0px" viewBox="0 0 900 900" className="species-back-img">
            <polygon points="131.33,104.88 769.33,104.88 765.83,723.38 354.83,794.38 131.33,794.38 " opacity="0.8" fill={color} />
        </svg>
    )
}

export default SpecieslBackImg;